import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { axiosClient } from "../../common/utils"

export default function ShortLinkRedirect() {
  const { key } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axiosClient
      .get(`/link-shortener/api/short-link-target/${key}/`)
      .then(({ data }) => navigate(data.target))
  })
}
